import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calculateGallerySize } from '../../../utils/functions';
import { Video, Audio, Avatar } from '../../common';
import { viewType } from '../../../utils/util';
import { setCurrentPage, setNumPages } from '../../../reduxStore/confSlice';
import {
  changeTrackResolution,
  changeTrackResolutionActiveSpeaker,
  localTracksFromVideo,
  createLocalTracksFromStream,
} from '../../../libs';
import { AttendeeName } from '../../common/web';
import AudioContainer from './video-layouts/audioContainer';
import GalleryView from './video-layouts/galleryView';
import { ShowActiveSpeakerOnFirstPage } from './utils/functions';
import ActiveSpeakerView from './video-layouts/activeSpeakerView';
import ActiveSpeakerExcluding from './video-layouts/activeSpeakerExcluding';
import PresentationView from './video-layouts/presentationView';
import ActiveSmallWindow from './activeSmallWindow';

const VideoContainer = () => {
  const dominantSpeaker = useSelector(state => state.conf.dominantSpeaker);
  const view = useSelector(state => state.conf.view);
  const maxVideos = useSelector(state => state.conf.maxVideos);
  const windowDimension = useSelector(state => state.conf.windowDimension);
  const chatClick = useSelector(state => state.conf.chatClick);
  const widgetClick = useSelector(state => state.conf.widgetClick);
  const attendeesClick = useSelector(state => state.conf.attendeesClick);
  const galleryViewParticipants = useSelector(
    state => state.conf.galleryViewParticipants,
  );
  const liveCaption = useSelector(state => state.transcription.liveCaption);
  const textFontSize = useSelector(state => state.transcription.textFontSize);
  const dispatch = useDispatch();
  const [mp4Video, setMp4Video] = useState(null);
  const extensionDetails = useSelector(state => state.conf.extensionDetails);
  const activeSmallWindowHide = useSelector(
    state => state.conf.activeSmallWindowHide,
  );
  const videoContainerSettings = useSelector(
    state => state.conf.videoContainerSettings,
  );
  const screenSharedJid = useSelector(state => state.conf.screenSharedJid);
  const mediaShareparticipantsJID = useSelector(
    state => state.conf.mediaShareparticipantsJID,
  );

  const galleryViewParticipantsCount = useMemo(() => {
    return galleryViewParticipants.length;
  }, [galleryViewParticipants]);

  const showActiveWindow = useMemo(() => {
    let imageShared =
      extensionDetails?.extensionid === 52199 &&
      extensionDetails?.type?.includes('image');
    let show =
      videoContainerSettings?.show_speaker_on_presentation &&
      !activeSmallWindowHide &&
      ((view === viewType.presentation &&
        screenSharedJid !== mediaShareparticipantsJID) ||
        imageShared);
    return show;
  }, [
    videoContainerSettings?.show_speaker_on_presentation,
    view,
    extensionDetails,
    mediaShareparticipantsJID,
    screenSharedJid,
    activeSmallWindowHide,
  ]);

  useEffect(() => {
    ShowActiveSpeakerOnFirstPage(showActiveWindow);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dominantSpeaker, maxVideos]);

  useEffect(() => {
    dispatch(calculateGallerySize(galleryViewParticipantsCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    galleryViewParticipantsCount,
    windowDimension,
    chatClick,
    attendeesClick,
    widgetClick,
    liveCaption,
    textFontSize,
  ]);

  useEffect(() => {
    console.log(mp4Video);
    if (mp4Video) {
      const leftVideo = document.getElementById('leftVideo');
      console.log('Video element found');
      leftVideo.load();
      leftVideo.addEventListener(
        'loadeddata',
        function () {
          console.log('video loaded =>');
          leftVideo.play();
          console.log('video play start=>');
          // Video is loaded and can be played
        },
        false,
      );
      leftVideo.onplay = function () {
        // leftVideo.addEventListener('canplay', () => {
        console.log('Getting video sreams=>');
        let stream;
        const fps = 0;
        if (leftVideo.captureStream) {
          stream = leftVideo.captureStream(fps);
        } else if (leftVideo.mozCaptureStream) {
          stream = leftVideo.mozCaptureStream(fps);
        } else {
          console.error('Stream capture is not supported');
          stream = null;
        }
        createLocalTracksFromStream(stream)
          .then(localTracksFromVideo)
          .catch(error => {
            throw error;
          });
      };
      // );
    }
  }, [mp4Video]);

  const renderView = () => {
    return <GalleryView showActiveWindow={false} setMp4Video={setMp4Video} />;
    // switch (view) {
    //   case 'gallery':
    //     return (
    //       <GalleryView showActiveWindow={false} setMp4Video={setMp4Video} />
    //     );
    //   case 'active':
    //     return (
    //       <ActiveSpeakerView
    //         largeWindow={true}
    //         showActiveWindow={showActiveWindow}
    //         setMp4Video={setMp4Video}
    //       />
    //     );
    //   case 'activeExcludingYourself':
    //     return (
    //       <ActiveSpeakerExcluding
    //         showActiveWindow={showActiveWindow}
    //         setMp4Video={setMp4Video}
    //       />
    //     );
    //   case 'presentation':
    //     return <PresentationView showActiveWindow={showActiveWindow} />;
    //   default:
    //     return (
    //       <GalleryView showActiveWindow={false} setMp4Video={setMp4Video} />
    //     );
    // }
  };

  return (
    <>
      <AudioContainer />
      {renderView()}

      {/* {showActiveWindow && (
        <ActiveSmallWindow>
          <ActiveSpeakerView showActiveWindow={showActiveWindow} setMp4Video={setMp4Video} />
        </ActiveSmallWindow>
      )} */}
    </>
  );
};
export default VideoContainer;
